/* src/Components/Estimating/Quote/QuotePDF.module.css */

.container {
  background-color: white;
  padding: 16px;
}

.collapse {
  margin-top: 24px;
}

.lineItemSection {
  margin-bottom: 24px;
}

.droppableArea {
  background: #fafafa;
  padding: 8px;
  border-radius: 4px;
  min-height: 100px;
}

.draggableItem {
  user-select: none;
  padding: 12px;
  margin: 0 0 8px 0;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dragging {
  background: #e6f7ff;
}

.itemContent {
  flex: 1;
}

.itemActions {
  display: flex;
  align-items: center;
}

.previewContainer {
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  height: 80vh;
  overflow: auto;
  background-color: #fff;
}

/* src/Components/Estimating/Quote/QuotePDF.module.css */

.container {
  margin: 0;
}



/* In your CSS module or stylesheet */

.stickyPreview {
  position: sticky;
  top: 0;
  height: 100%;
  overflow: auto;
}

.previewContainer {
  width: 100%;
  height: 100%;
}


.details-table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}
.details-table td {
  padding: 4px 8px;
}
.left-column {
  text-align: left;
}
.right-column {
  text-align: right;
}

@page {
  size: A4;
  margin: 20mm;
}
