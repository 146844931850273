
@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected,
:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
    background-color: #001529 !important;
}

:where(.css-dev-only-do-not-override-19hgxm3).ant-menu-dark .ant-menu-item-selected,:where(.css-dev-only-do-not-override-19hgxm3).ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-19hgxm3).ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, :where(.css-dev-only-do-not-override-19hgxm3).ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected, :where(.css-dev-only-do-not-override-19hgxm3).ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected {
      background-color: #001529 !important;
}


.ant-table-nested tbody {
    background-color: rgba(173, 216, 230, 0.25)
;
}

.ant-table-nested .ant-table-thead th{
    background-color:lightsteelblue !important;
}

/* App.css */
.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent !important; /* Remove background color */
    color: white !important; /* Set text color to white */
}

.ant-menu-dark .ant-menu-item-selected a {
    color: white !important; /* Ensure the link is also white */

}

.card-hover-effect {
  cursor: pointer; /* Ensures the cursor changes to pointer on hover */
  border: 1px solid transparent; /* Adds a transparent border by default */
}

.card-hover-effect:hover {
  border-color: #08c; /* Changes border color on hover */
}

.opacity{
  opacity: 0.7;
  font-style:italic;
  font-size:14
}

.logo-wrapper {
  display: flex;
  align-items: center;
  transition: padding 0.3s, height 0.3s;
}

.logo-wrapper.minimized {
  padding-right: 10px;
  height: 50px;
}

.logo-wrapper.normal {
  padding-right: 20px;
  height: 85px;
}

.logo {
  transition: width 0.3s;
}

.logo.minimized {
  width: 32.5px;
}

.logo.normal {
  width: 180px;
}

.user-button-wrapper {
  transition: transform 0.3s, height 0.3s;
}

.user-button-wrapper.minimized {
  transform: scale(0.7); /* Scale down the button */
  height: 50px; /* Adjust the height to match the header */
}

.user-button-wrapper.normal {
  transform: scale(1); /* Normal size */
  height: 85px; /* Adjust the height to match the header */
}

.box-shadow {
box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.small-table .ant-table {
  font-size: 12px;
}

.small-table .ant-table-thead > tr > th {
  font-size: 12px;
}

.small-table .ant-table-tbody > tr > td {
  font-size: 12px;
}


span.ant-dropdown-trigger.ant-table-filter-trigger.active{
  color: #001529;
}





body, .quote-container {
  font-family: 'Poppins', sans-serif;
  font-size:12
}

.quote-container .quote-content {
  background-color: white;
  padding: 20px;
}

td .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 12px !important;
    height: 100%;
    padding: 0 0 0 0px !important;
    border-radius: 0px 0 0 0px;
    background-color: "white" !important;
}

td .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: white !important;
    border: none !important;
    border-radius: 3px 0 0 3px;
}

td .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 32px !important;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
}

.displayOnlyPhone >  .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;

    border: none !important;
    border-radius: 3px 0 0 3px;
}



.react-tel-input .form-control{
  width: 100% !important;
}


.red-input input {
  color: #f10000 !important;
}

.zero-qty{
  background-color: #f10000 !important;
  color: white !important;
}

.zero-qty input{
  color: white !important;
}

.custom-upload .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Style for the resizable handle */
.react-resizable-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: col-resize;
  width: 5px;
  height: 100%;
}

/* Optional: Add padding to make the handle more visible */
.react-resizable-handle::after {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background: transparent;
}

.ant-table .ant-table-tbody > tr > td {
  padding: 8px !important;
}

/* Add this CSS to your stylesheet */
.small-table .ant-table-cell {
  padding:8px; /* Adjust the padding to reduce space */
}

.small-table .ant-table-thead > tr > th {
  padding: 8px; /* Adjust the padding for headers as well */
}

.small-table .ant-table-thead > tr > th, 
.small-table .ant-table-tbody > tr > td {
  font-size: 12px; /* Reduce font size if needed */

}


/* Add this CSS to your stylesheet */
.smaller-table .ant-table-cell {
  padding: 6px; /* Adjust the padding to reduce space */
}

.smaller-table .ant-table-thead > tr > th {
  padding: 6px; /* Adjust the padding for headers as well */
}

.smaller-table .ant-table-thead > tr > th, 
.smaller-table .ant-table-tbody > tr > td {
  font-size: 11px; /* Reduce font size if needed */

}
.no-wrap{
  white-space: nowrap;
}

.clickable-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.transparent-select .ant-select-selector{
  background-color: transparent !important;
  border: none !important;
}

.return-line{
  background-color: #e6f7ff !important;
}

.non-return-line{ 
  font-style: italic;
  opacity:0.6
}

/* Apply background color to <th> elements with expanded-row-header class */
th.expanded-row-header {
  background-color: #BAE7FF !important; /* Light blue background for the expanded row header */
  padding: 8px; /* Optional: adjust padding */
  font-size: 14px; /* Optional: adjust font size */
  border-bottom: 1px solid #91D5FF !important; /* Optional: adjust border color */
  text-align: center; /* Optional: center the text */
}




/* styles.css */
.expanded-row {
  background-color: #e6f7ff !important; /* Light blue */
}

.expanded-row-header{
  background-color: #e6f7ff !important; /* Darker light blue */
}

.expanded-header{
    background-color: #bae7ff !important; /* Light blue for expanded rows */
}

.expanded-header:hover{
    background-color: #bae7ff !important; /* Light blue for expanded rows */
}

.ant-table-expanded-row > td {
  background-color: #e6f7ff !important; /* Light blue for expanded rows */
}


.expanded-row-white {
  background-color: white !important; /* White */
}


/* Add this CSS to your stylesheet (e.g., Preconstruction.css) */

/* Keyframes for the spin animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Class to apply the spin animation */
.animated-icon {

}

strong {
  font-weight: 600;
}

