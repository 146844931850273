body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: contain;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin-top: 0px;
  margin-bottom:6px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #557FCB !important;
  z-index: 2;
}

/* Custom override for Ant Design select dropdown */
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0 !important;
  line-height: 1.2 !important; /* Adjust line height as needed */
  align-self: center !important;
}

/* AddModuleModal.css */

.centered-label .ant-form-item-label > label {
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 0px;
  padding-left:6px;
}

/* src/Components/Estimating/Quote/QuotePDF.module.css */

.container {
  background-color: white;
  padding: 16px;
}

.collapse {
  margin-top: 24px;
}

.lineItemSection {
  margin-bottom: 24px;
}

.droppableArea {
  background: #fafafa;
  padding: 8px;
  border-radius: 4px;
  min-height: 100px;
}

.draggableItem {
  user-select: none;
  padding: 12px;
  margin: 0 0 8px 0;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dragging {
  background: #e6f7ff;
}

.itemContent {
  flex: 1;
}

.itemActions {
  display: flex;
  align-items: center;
}

.previewContainer {
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  height: 80vh;
  overflow: auto;
  background-color: #fff;
}

.mobile-input{
  padding: 12px;
  font-size: 18px;
}

.mobile-label{
  font-size: 18px;
}

